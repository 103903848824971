.hold-right {
    background: linear-gradient(135deg, #42d9fe 0%, #2872fa 100%);
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    color: #ffffff;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: absolute;
    top: 8px;
    right: -22px;
    width: 80px;
    text-align: center;
    padding: 2px;
}

.hold-left {
    background: linear-gradient(135deg, #42d9fe 0%, #2872fa 100%);
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    color: #ffffff;
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: absolute;
    top: 8px;
    left: -22px;
    width: 80px;
    text-align: center;
    padding: 2px;
}

.triangle-up {
    display: inline-block;
    position: absolute;
    top: -22px;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-bottom-color: #eef6ff;
}

.MuiSnackbarContent-action {
    padding-left: 0px !important;
}
